/* Utility class to make sidebar nav and api testing area stick to top when user scrolls down */
.sticky {
  position: sticky;
  top: 64px;
}

/* Content of the documentation excluding the navigation sidebar */
.doc-content {
  display: flex;
  padding-left: 0;
}

/* For documentation that takes 2 columns instead of 1 */
.doc-content--center {
  min-height: 100vh;
  text-align: center;
  margin: 0 auto;
}

.doc-content__left {
  border-right: 1px solid #e5e5e5;
}

.request-body {
  padding-left: 15px;
  padding-right: 15px;
}

.request-body + .request-body {
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

.request-body__title {
  color: #050593;
  font-weight: bold;
  margin-right: 4px;
}

.doc-content__inputs {
  text-align: left;
}

.terminal-container {
  border-radius: 10px;
}

/* Tablet and Desktop */
@media (min-width: 992px) {
  .doc-content__left,
  .doc-content__right {
    padding-top: 64px;
  }
}