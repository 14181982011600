.navbar-nav a.nav-link {
  font-family: Avenir-Medium, sans-serif;  
}

.navbar--header .navbar-nav a {
  transition: all 0.2s ease;
}

.navbar--header .navbar-nav a + a {
  margin-left: 4px;
}

.navbar--header {
  background: #08157c;
  width: 100%;
  justify-content: space-between;
}

.header-logo {
  width: 100%;
  max-width: 108px;
}

.navbar-vertical {
  padding: 0;
  flex-direction: column;
  text-align: left;
}

.navbar-vertical .nav a.nav-link {
  color: #fff;
  width: 100%;
  transition: all 0.2s ease;
}

.navbar-vertical .nav a + a {
  margin-top: 4px;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link.active {
  color: #050593;
  background: #F3FBFF;
  border-radius: 8px;
}

.doc-sidebar {
  background-color: #2E3439;
  max-width: 264px;
}

.navbar-toggler.sidebar-toggler {
  display: block !important;
  font-size: 1rem;
  position: absolute;
  top: -50px;
  transition: border-color 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.navbar-toggler.sidebar-toggler:hover {
  border-color: #fff !important;
}

.navbar-toggler.sidebar-toggler:focus {
  outline: none;
}

/* Desktop */

@media (min-width: 992px) {
  .visible-mobile, .mobile-nav {
    display: none;
  }
  
  .is-sidebar-collapsed .doc-sidebar {
    text-align: center;
    max-width: 100px;
  }
  
  .is-sidebar-collapsed .navbar-vertical {
    text-align: center;
  }
  
  .is-sidebar-collapsed .doc-content {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .doc-sidebar, .doc-content--center, .doc-content__left, .doc-content__right {
    padding-top: 64px;
  }
  
  .mobile-nav + .navbar-collapse {
    height: auto !important;
  }
}

/* Mobile + Tablet */

@media (max-width: 991px) {
  .doc-sidebar {
    background: #08157c;
    margin-bottom: 64px;
    max-width: 100%;
  }
  
  .doc-content {
    flex-direction: column;  
  }
  
  .navbar--header {
    display: none;
  }
  
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .navbar-collapse .navbar-nav {
    text-align: center;
  }
}