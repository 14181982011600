@font-face {
  font-family: Avenir-Book;
  src: url(/static/media/Avenir-Book.538b6b32.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Avenir-Medium;
  src: url(/static/media/Avenir-Medium.ccbca1fb.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: Avenir-Book, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.input-error > .ant-select-selector {
  border-color: #e31920 !important;
}

.error-message {
  font-style: normal;
  font-size: 16px;
  color: #e31920;
}

.navbar-nav a.nav-link {
  font-family: Avenir-Medium, sans-serif;  
}

.navbar--header .navbar-nav a {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.navbar--header .navbar-nav a + a {
  margin-left: 4px;
}

.navbar--header {
  background: #08157c;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.header-logo {
  width: 100%;
  max-width: 108px;
}

.navbar-vertical {
  padding: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
}

.navbar-vertical .nav a.nav-link {
  color: #fff;
  width: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.navbar-vertical .nav a + a {
  margin-top: 4px;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link.active {
  color: #050593;
  background: #F3FBFF;
  border-radius: 8px;
}

.doc-sidebar {
  background-color: #2E3439;
  max-width: 264px;
}

.navbar-toggler.sidebar-toggler {
  display: block !important;
  font-size: 1rem;
  position: absolute;
  top: -50px;
  -webkit-transition: border-color 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: border-color 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.navbar-toggler.sidebar-toggler:hover {
  border-color: #fff !important;
}

.navbar-toggler.sidebar-toggler:focus {
  outline: none;
}

/* Desktop */

@media (min-width: 992px) {
  .visible-mobile, .mobile-nav {
    display: none;
  }
  
  .is-sidebar-collapsed .doc-sidebar {
    text-align: center;
    max-width: 100px;
  }
  
  .is-sidebar-collapsed .navbar-vertical {
    text-align: center;
  }
  
  .is-sidebar-collapsed .doc-content {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .doc-sidebar, .doc-content--center, .doc-content__left, .doc-content__right {
    padding-top: 64px;
  }
  
  .mobile-nav + .navbar-collapse {
    height: auto !important;
  }
}

/* Mobile + Tablet */

@media (max-width: 991px) {
  .doc-sidebar {
    background: #08157c;
    margin-bottom: 64px;
    max-width: 100%;
  }
  
  .doc-content {
    -webkit-flex-direction: column;
            flex-direction: column;  
  }
  
  .navbar--header {
    display: none;
  }
  
  .mobile-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
  }
  
  .navbar-collapse .navbar-nav {
    text-align: center;
  }
}
/* Utility class to make sidebar nav and api testing area stick to top when user scrolls down */
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
}

/* Content of the documentation excluding the navigation sidebar */
.doc-content {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
}

/* For documentation that takes 2 columns instead of 1 */
.doc-content--center {
  min-height: 100vh;
  text-align: center;
  margin: 0 auto;
}

.doc-content__left {
  border-right: 1px solid #e5e5e5;
}

.request-body {
  padding-left: 15px;
  padding-right: 15px;
}

.request-body + .request-body {
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

.request-body__title {
  color: #050593;
  font-weight: bold;
  margin-right: 4px;
}

.doc-content__inputs {
  text-align: left;
}

.terminal-container {
  border-radius: 10px;
}

/* Tablet and Desktop */
@media (min-width: 992px) {
  .doc-content__left,
  .doc-content__right {
    padding-top: 64px;
  }
}
.collapseButton:focus {
  outline: none !important;
  color: 'red';
}

.collapseButton:active {
  outline: none !important;
}

.collapse-container {
  border: 1px solid #050593;
}

.collapse__trigger {
  position: relative;
  -webkit-transition: border-radius ease 0.3s;
  transition: border-radius ease 0.3s;
}

.collapse__trigger:focus {
  box-shadow: none !important;
}

.collapse__trigger:hover {
  color: #fff;
  background-color: #050593;
}

.is-expanded .collapse__chevron {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.collapse-container.is-expanded .collapse__trigger {
  background-color: #050593;
  color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.collapse__chevron {
  fill: #c1c9d2;
  position: absolute;
  right: 12px;
  -webkit-transition: -webkit-transform ease 0.3s;
  transition: -webkit-transform ease 0.3s;
  transition: transform ease 0.3s;
  transition: transform ease 0.3s, -webkit-transform ease 0.3s;
}

.collapse__content {
  margin-top: 8px;
}
