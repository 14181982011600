@font-face {
  font-family: Avenir-Book;
  src: url(./fonts/Avenir-Book.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Avenir-Medium;
  src: url(./fonts/Avenir-Medium.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: Avenir-Book, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.input-error > .ant-select-selector {
  border-color: #e31920 !important;
}

.error-message {
  font-style: normal;
  font-size: 16px;
  color: #e31920;
}
