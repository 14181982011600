.collapseButton:focus {
  outline: none !important;
  color: 'red';
}

.collapseButton:active {
  outline: none !important;
}

.collapse-container {
  border: 1px solid #050593;
}

.collapse__trigger {
  position: relative;
  transition: border-radius ease 0.3s;
}

.collapse__trigger:focus {
  box-shadow: none !important;
}

.collapse__trigger:hover {
  color: #fff;
  background-color: #050593;
}

.is-expanded .collapse__chevron {
  transform: rotate(180deg);
}

.collapse-container.is-expanded .collapse__trigger {
  background-color: #050593;
  color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.collapse__chevron {
  fill: #c1c9d2;
  position: absolute;
  right: 12px;
  transition: transform ease 0.3s;
}

.collapse__content {
  margin-top: 8px;
}